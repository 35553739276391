<template>
  <v-container fluid class="py-6">
    <!-- Sales Dialog -->
    <v-dialog v-model="sale_dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 font-weight-bold">
          {{ dialog_title }}
        </v-card-title>
        <v-card-text>
          <v-select v-if="behavior == change_sale_behavior.ChangeSale" v-model="selected_sale" :items="sales_filter"
            item-text="full_name" item-value="id" label="選擇業務" outlined dense></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="sale_dialog = false">
            取消
          </v-btn>
          <v-btn color="blue darken-1" text @click="doActionByBehavior" :disabled="!selected_sale">
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mx-6">
      <v-col>
        <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
          <v-row>
            <v-col cols="auto">
              <v-avatar width="74" height="74" class="shadow border-radius-lg">
                <img src="@/assets/img/team-4.jpg" alt="Avatar" class="border-radius-lg" />
              </v-avatar>
            </v-col>
            <v-col cols="auto" align-self="center">
              <h4 class="mb-1 text-h3 text-grt font-weight-bold">變更使用者</h4>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn icon class="mx-4 my-4" @click="$router.go(-1)">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card>
        <user-basic-info :user_profile="profile" :me="false" :edit_mode="edit_mode_enum.Edit"></user-basic-info>
      </v-col>
    </v-row>
    <v-row class="mx-6">
      <v-col>
        <CustomerTableBse ref="table_base" :auto_search="auto_search" :searchInfo="searchInfo" :selectable="true"
          :propagate="true" @update_customer="onUpdateCustomer">
          <template v-slot:header>
            <div class="d-flex align-center px-6 pt-2">
              <v-btn-toggle v-model="searchInfo.data_source" tile color="red accent-3" group @change="onButtonChanged">
                <v-btn
                  v-for="item in data_source_enum_str"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
              <v-spacer> </v-spacer>
              <v-btn class="
                  font-weight-bolder
                  btn-default
                  bg-gradient-primary
                  py-4
                  px-4
                  mx-2
                  mt-sm-auto mt-4
                " @click="showSaleDialog(change_sale_behavior.ChangeSale)">
                變更業務
              </v-btn>

              <v-btn class="
                  font-weight-bolder
                  btn-default
                  bg-gradient-default
                  py-4
                  mt-sm-auto mt-4
                " @click="showSaleDialog(change_sale_behavior.Publish)">
                設為公開
              </v-btn>
            </div>
          </template>
        </CustomerTableBse>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { edit_mode_enum, change_sale_behavior,data_source_enum_str } from "@/definitions.js";
import UserBasicInfo from "./Widgets/UserBasicInfo.vue";
import CustomerTableBse from "@/views/Pages/CRM/CustomerTableBase";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { behavior } from "d3";
export default {
  name: "Edit-User",

  components: {
    UserBasicInfo,
    CustomerTableBse,
  },
  mixins: [HttpCommonMixin],
  data() {
    return {
      auto_search: false,
      edit_mode_enum: edit_mode_enum,
      change_sale_behavior: change_sale_behavior,
      data_source_enum_str,
      profile: {},
      searchInfo: {
        full_name: null,
        id_full: null,
        company: null,
        address: null,
        phone: null,
        data_source: "指定",
        group: null,
        sale_id: null,
      },
      sales_filter: [],
      sale_dialog: false,
      selected_sale: null,
      behavior: null,
     
    };
  },
  created() {
    if (this.$route.params && this.$route.params.data) {
      this.profile = JSON.parse(this.$route.params.data);
    }
  },
  computed: {
    dialog_title() {
      if (this.behavior === change_sale_behavior.ChangeSale) {
        return "變更業務";
      } else if (this.behavior === change_sale_behavior.Publish) {
        return "設為公開";
      }
      return "";
    },
  },
  mounted() {
    this.doSearchBySale();
  },
  methods: {
    onUpdateCustomer(item) {
      this.doSearchBySale();
    },
    showSaleDialog(type) {
      if (!this.$refs.table_base.selected.length) {
        this.$swal("請先選擇客戶");
        return;
      }
      this.behavior = type;
      this.selected_sale = null;
      this.getSales();
      this.sale_dialog = true;
    },
    doActionByBehavior() {
      if (this.behavior === change_sale_behavior.ChangeSale) {
        this.changeSale();
      } else if (this.behavior === change_sale_behavior.Publish) {
        this.publish();
      }
    },
    async changeSale() {
      if (!this.$refs.table_base.selected.length) return;

      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/change-sale`;
      const data = {
        from_id: this.profile.id,
        to_id: this.selected_sale,
        case_ids: this.$refs.table_base.selected.map(
          (item) => item.related_person.case_id
        ),
      };

      try {
        await this.doHttpPost(url, data, this.onChangeDone);
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "變更業務失敗", "error");
      } finally {
        this.sale_dialog = false;
        this.$refs.table_base.selected = [];
        this.behavior = null;
      }
    },
    onChangeDone() {
      this.$swal("成功", "已變更業務", "success");
      this.doSearchBySale();
    },
    doSearchBySale(){
      this.searchInfo.sale_id = this.profile.id;
      if(this.searchInfo.sale_id == null){
        return;
      }
      this.$refs.table_base.getCustomerByCondition(this.searchInfo);
    },
    getSales() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/sales`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };

      this.loading = true;
      this.doHttpGet(
        url,
        params,
        this.setSalesResult,
        (error) => console.log(error),
        () => (this.loading = false)
      );
    },
    setSalesResult(data) {
      // console.log(data);
      this.sales_filter = data.filter((item) => item.id !== this.profile.id);
      this.selected_sale = this.sales_filter[0]?.id;
    },
    publish() {
      const customer_ids = this.$refs.table_base.selected.map(
        (item) => item.id
      );
      console.log(customer_ids);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/change-public`;
      const data = {
        customer_ids: customer_ids,
      };
      try {
        this.doHttpPost(url, data, this.onPublishDone);
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "設為公開失敗", "error");
      } finally {
        this.sale_dialog = false;
        this.$refs.table_base.selected = [];
        this.behavior = null;
      }
    },
    onPublishDone() {
      this.$swal("成功", "已公開", "success");
      this.$refs.table_base.getCustomerByCondition(this.searchInfo);
    },
    onButtonChanged(){
      if(this.searchInfo.data_source == 0){
        delete this.searchInfo.data_source;
      }
      this.$refs.table_base.getCustomerByCondition(this.searchInfo);
    }
  },
};
</script>
